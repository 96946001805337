import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridFilterModel,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSortItem,
  GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import DialogInput from "./Dialog";
import { Box } from "@mui/material";
import { IPagination } from "../types/customTypes";
import { styled } from "styled-components";
import theme from "../theme/theme";

const StyledBox = styled(Box)(() => ({
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
  },
}));

interface PageManagementGridProps {
  rows: any;
  onDelete: (rowId: GridRowId) => void;
  onUpdate?: (updatedField: GridRowModel) => void;
  columnFields: GridColDef[];
  paginationModel: { pageSize: number; page: number };
  totalItems: number;
  isFetching: boolean;
  setPagination: (newPagination: IPagination) => void;
  onSearchCriteriaChange?: (queryOptions: GridFilterModel) => void;
  customActions?: GridColDef;
  checkboxSelection?: boolean;
  onSelectionModelChange?: (selectedIds: GridRowId[]) => void;
  sortModel?: GridSortItem[];
  noActionButton?: boolean;
}

function PageManagementGrid({
  rows,
  onDelete,
  onUpdate,
  columnFields,
  paginationModel,
  totalItems,
  isFetching,
  setPagination,
  customActions,
  checkboxSelection,
  onSelectionModelChange,
  sortModel,
  noActionButton,
}: PageManagementGridProps) {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null>(null);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (rows) {
      setRowData(rows);
    }
  }, [rows, paginationModel]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id: GridRowId) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteRowId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleDelete = () => {
    if (deleteRowId) {
      setRowData((oldRowData) => oldRowData.filter((row: any) => row._id !== deleteRowId));

      onDelete(deleteRowId);
      setIsDeleteDialogOpen(false);
    }
  };

  const processRowUpdate = async (updatedRow: GridRowModel) => {
    if (onUpdate) {
      onUpdate(updatedRow);
    }
    setRowData((prevData: any) => prevData.map((row: any) => (row.id === updatedRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [...columnFields];

  if (noActionButton) {
    columns.push();
  } else if (customActions) {
    columns.push({
      ...customActions,
    });
  } else {
    columns.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={() => handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
        ];
      },
    });
  }

  return (
    <StyledBox>
      <div className="data-grid-container">
        <DataGrid
          sx={{ margin: "20px 60px 0 0" }}
          rows={rowData}
          columns={columns}
          sortingOrder={["desc", "asc"]}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          getRowId={(row) => row.id || row._id}
          paginationMode="server"
          keepNonExistentRowsSelected
          paginationModel={paginationModel}
          onPaginationModelChange={setPagination}
          rowCount={totalItems}
          pageSizeOptions={[10, 30, 50, 100, 150]}
          disableColumnFilter
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: {
                pageStyle: ".MuiDataGrid-root .MuiDataGrid-main { color: rgba(0, 0, 0, 0.87); }",
              },
            },
          }}
          loading={isFetching}
          onProcessRowUpdateError={(error) => console.log(error)}
          checkboxSelection={checkboxSelection}
          onRowSelectionModelChange={onSelectionModelChange}
          sortModel={sortModel}
        />
        {isDeleteDialogOpen && (
          <DialogInput
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
            onConfirm={handleDelete}
            title={"Confirm deletion"}
            content="Are you sure you want to delete this data? This action cannot be undone."
          />
        )}
      </div>
    </StyledBox>
  );
}

export default PageManagementGrid;