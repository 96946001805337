import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ILogin } from "../../types/customTypes";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "../../store/api/authenticationApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import usePersist from "../../hooks/usePersist";
import CenteredContainer from "../../components/CenteredContainer";
import UnauthorizedLoginAlert from "../../components/UnauthorizedLoginMessage";

export default function LogIn() {
  const { register, handleSubmit, formState } = useForm<ILogin>();
  const { errors } = formState;
  const [login, loginMutationResult] = useLoginMutation();
  const [persist, setPersist] = usePersist();
  const { isSuccess, isError, error } = loginMutationResult;

  const navigate = useNavigate();
  const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginUnAuthorized, setIsLoginUnauthorized] = useState(false);

  const onSubmit = async (loginDetails: ILogin) => {
    await login(loginDetails);
  };

  const closeAlert = () => {
    setErrorMessage("");
  };

  const handleToggleCheckbox = () => setPersist((prev) => !prev);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
    } else {
      console.error("setUserDetails is not available on the window object.");
    }
    setErrorMessage("");

    if (isError) {
      const err: any = error;
      if (err.status === 401) {
        // If the error status is 401, navigate the user back to the login page
        // navigate("/login");
        setIsLoginUnauthorized(true);
      } else {
        const errorMessage = err?.data?.message ? err.data?.message : "Login failed";
        setErrorMessage(errorMessage);
      }
    }
  }, [isSuccess, isError, error]);

  useEffect(() => {
    setPersist(false);
  }, []);

  return (
    <CenteredContainer>
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5" fontWeight="bold">
          Sign in
        </Typography>

        <Box sx={{ mt: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              autoFocus
              {...register("email", {
                required: "Please enter email address",
                pattern: {
                  value: emailPattern,
                  message: "Invalid email address",
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              id="password"
              {...register("password", {
                required: "Please enter password",
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <FormControlLabel control={<Checkbox />} label="Remember me" onChange={handleToggleCheckbox} />

            {errorMessage && (
              <Alert severity="error" onClose={closeAlert} sx={{ width: "100%", mt: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
          </form>

          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Grid item textAlign="center" mt={2} display="flex" justifyContent="space-between">
            <Typography variant="body2">
              Didn't receive verification email?
              <Typography component="span" variant="body2" ml={1}>
                <Link href="/send-verification-email">Click to resend</Link>
              </Typography>
            </Typography>
          </Grid>
        </Box>
      </Container>
    </CenteredContainer>
  );
}
