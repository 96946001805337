import React, { useState, useEffect } from "react";
import { useGetBankAccountsQuery, useGetTransactionsQuery, useGetUrlsQuery } from "../../store/api/bankAccountApi";
import { IBankAccount, IQueryTransaction, ITransaction } from "../../types/customTypes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Card, CardContent, Typography, Button, Skeleton, Box, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Grid } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CloseIcon from "@mui/icons-material/Close";

import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function ShowBankAccount() {
  const [selectedProvider, setSelectedProvider] = React.useState("");
  const [providerAccounts, setProviderAccounts] = useState<IBankAccount[]>([]);
  const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
  const [showAccounts, setShowAccounts] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showDetails, setShowDetails] = useState<{ [key: string]: boolean }>({});
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<{ accountId: string; connectedAccountId: string; displayName: string } | null>(null);
  const [showTransactions, setShowTransactions] = useState<boolean>(false);
  const { data, isSuccess, isLoading } = useGetBankAccountsQuery();
  const [transactionError, setTransactionError] = useState<string | null>(null);
  const [queryParams, setQueryParams] = useState<IQueryTransaction>({
    indexId: "",
    fromDate: "",
    toDate: "",
    accountId: "",
  });
  const object = useGetTransactionsQuery(queryParams);
  const { data: urlsData, isSuccess: isUrlsSuccess, isLoading: isUrlsLoading } = useGetUrlsQuery();

  useEffect(() => {
    if (isSuccess) {
      setBankAccounts(data.data);
    }
  }, [data, isSuccess]);

  const groupByProvider = () => {
    const groupedAccounts: { [key: string]: IBankAccount[] } = {};

    bankAccounts.forEach((account) => {
      const providerName = account.provider_name;

      if (!groupedAccounts[providerName]) {
        groupedAccounts[providerName] = [];
      }

      groupedAccounts[providerName].push(account);
    });

    return groupedAccounts;
  };

  const handleAddAccount = async () => {
    try {
      if (urlsData) {
        const addAccountUrl = urlsData.authUrl;
        if (addAccountUrl) {
          window.location.href = addAccountUrl;
        } else {
          console.error("Error: Add account URL is not available");
        }
      } else {
        console.error("Error: URLs data is not available");
      }
    } catch (error) {
      console.error("Error adding account:", error);
    }
  };

  const handleLearnMore = (providerName: string, bankAccounts: IBankAccount[]) => {
    setSelectedProvider(providerName);
    setProviderAccounts(groupByProvider()[providerName]);
    setShowAccounts(true);
  };

  const handleClose = () => {
    setShowAccounts(false);
    setSelectedProvider("");
    setTransactions([]);
    setSelectedAccount({
      accountId: "",
      connectedAccountId: "",
      displayName: "",
    });
  };

  const handleOpenDialog = (accountId: string, connectedAccountId: string, displayName: string) => {
    setShowTransactions(false);
    setSelectedAccount({ accountId, connectedAccountId, displayName });
    setTransactions([]);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleApplyDates = () => {
    if (selectedAccount && selectedAccount.accountId === queryParams.indexId && selectedAccount.connectedAccountId === queryParams.accountId) {
      setShowTransactions(true);
    }
    setShowDialog(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleSeeDetails = (accountId: string) => {
    setTransactions([]);
    setShowDetails((prevShowDetails) => ({
      ...prevShowDetails,
      [accountId]: !prevShowDetails[accountId],
    }));
  };

  useEffect(() => {
    if (startDate && endDate) {
      const jsStartDate = new Date(startDate);
      const jsEndDate = new Date(endDate);
      const dateDiff = jsEndDate.getTime() - jsStartDate.getTime();
      const maxAllowedDiff = 90 * 24 * 60 * 60 * 1000;
      if (dateDiff > maxAllowedDiff) {
        window.alert("Error: The selected date range exceeds the maximum allowed difference of 3 months.");
      } else {
        const formattedStartDate = jsStartDate.toISOString().split("T")[0];
        const formattedEndDate = jsEndDate.toISOString().split("T")[0];
        setQueryParams({
          indexId: selectedAccount?.accountId || "",
          fromDate: formattedStartDate,
          toDate: formattedEndDate,
          accountId: selectedAccount?.connectedAccountId || "",
        });
      }
    }
  }, [startDate, endDate, selectedAccount]);

  useEffect(() => {
    if (object.isFetching) {
      setTransactions([]);
      setTransactionError(null);
    } else if (object.isSuccess) {
      const newTransactions = object.data?.data ?? [];
      setTransactions(newTransactions);
      setTransactionError(null);
    } else if (object.isError) {
      console.error("Error fetching transactions:", object.error);
      setTransactions([]);
      setTransactionError("Error fetching transactions. Please try again.");
    }
  }, [object.isFetching, object.isSuccess, object.isError, object.data]);

  const columns: GridColDef[] = [
    { field: "timestamp", headerName: "Time", width: 150 },
    { field: "description", headerName: "Description" },
    { field: "transaction_category", headerName: "Transaction Category" },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      width: 150,
      renderCell: (params) => (
        <div style={{ backgroundColor: params.row.transaction_type === "DEBIT" ? "rgba(255, 0, 0, 0.2)" : "rgba(0, 255, 0, 0.2)" }}>
          {params.row.transaction_type}
        </div>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => (
        <div style={{ color: params.row.transaction_type === "DEBIT" ? "red" : "green" }}>
          {params.value} {params.row.currency}
        </div>
      ),
    },
    {
      field: "running_balance",
      headerName: "Balance",
      width: 150,
      renderCell: (params) => (
        <div style={{ color: "blue" }}>
          {params.value.amount} {params.row.running_balance.currency}
        </div>
      ),
    },
  ];

  return (
    <div>
      {isLoading && (
        <div>
          <Skeleton variant="rectangular" width="100%" height={200} />
          <Skeleton variant="rectangular" width="100%" height={200} />
        </div>
      )}
      <Box sx={{ top: 60, left: 135 }}>
        <Grid item md={6} sm={12}>
          <Typography variant="h5" gutterBottom fontWeight="bold" margin="15px">
            Bank Accounts
          </Typography>
        </Grid>
        <Button size="large" color="secondary" variant="contained" startIcon={<AddCircleIcon />} onClick={handleAddAccount}>
          Add Account
        </Button>
      </Box>
      {!isLoading &&
        Object.keys(groupByProvider()).map((providerName) => (
          <Card key={providerName} sx={{ width: "100%", mb: 2, mt: 5, backgroundColor: "#a5d6a7" }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {providerName}
              </Typography>
              <Stack direction="row" spacing={2} mt={2} mb={1}>
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  startIcon={<MenuOpenIcon />}
                  onClick={() => handleLearnMore(providerName, bankAccounts)}
                >
                  See All Accounts
                </Button>
                <Button size="small" color="secondary" variant="contained" startIcon={<CloseIcon />} onClick={handleClose}>
                  Close
                </Button>
              </Stack>
              {selectedProvider === providerName && showAccounts && (
                <div>
                  {providerAccounts.map((account) => (
                    <Card key={account.account_type} sx={{ width: "100%", mb: 2, backgroundColor: "##f5f5f5" }}>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {account.display_name}
                        </Typography>

                        <Stack direction="row" spacing={2} mt={2}>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            startIcon={showDetails[account.id] ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                            onClick={() => handleSeeDetails(account.id)}
                          >
                            {showDetails[account.id] ? "Hide Details" : "See Details"}
                          </Button>
                          <Button
                            size="small"
                            color="secondary"
                            variant="contained"
                            startIcon={<DateRangeIcon />}
                            onClick={() => handleOpenDialog(account.id, account.connected_account_id, account.display_name)}
                          >
                            Select Dates
                          </Button>
                        </Stack>
                        {showDetails[account.id] && (
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Account Number:</strong> {account.account_number}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Account Type:</strong> {account.account_type}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Provider Name:</strong> {account.provider_name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  {/* <Typography variant="body2">
                                    <strong>Balance:</strong> {account.balance}
                                  </Typography> */}

                                  <Typography variant="body2">
                                    <strong>Sort Code:</strong> {account.sort_code}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Currency:</strong> {account.currency}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Balance:</strong> {account.balance}
                                  </Typography>
                                  {/* <Typography variant="body2">
                                  <strong>Sort Code:</strong> {account.sort_code}
                                </Typography> */}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>True Layer Account Id:</strong> {account.id}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Overdraft:</strong> {account.overdraft}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body2">
                                    <strong>Display Name:</strong> {account.display_name}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        )}

                        {showTransactions && selectedAccount && selectedAccount.accountId === account.id && (
                          <div>
                            {transactionError ? (
                              <div style={{ color: "red", marginBottom: "10px", marginTop: "10px" }}>{transactionError}</div>
                            ) : object.isFetching ? (
                              <Button disabled>
                                <CircularProgress size={20} sx={{ marginRight: 1 }} />
                                Loading...
                              </Button>
                            ) : (
                              <>
                                <Typography variant="h6" component="div" sx={{ marginTop: 2 }}>
                                  Transactions for {selectedAccount.displayName}
                                </Typography>
                                <div className="data-grid-container">
                                  <DataGrid
                                    sx={{ margin: "20px 60px 0 0", width: "100%" }}
                                    rows={transactions}
                                    getRowId={(row) => row.transaction_id}
                                    columns={columns.map((col) => ({
                                      ...col,
                                      width: 300,
                                    }))}
                                    pageSizeOptions={[10, 20, 50, 100]}
                                    pagination={true}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        ))}

      <Dialog open={showDialog} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle>Select Dates</DialogTitle>
        <DialogContent>
          <div style={{ width: "400px", padding: "20px" }}>
            <Stack spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={startDate}
                  label="Start date"
                  maxDate={endDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <DatePicker
                  value={endDate}
                  label="End date"
                  minDate={startDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button size="small" color="secondary" variant="contained" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button size="small" color="secondary" variant="contained" onClick={handleApplyDates}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
