import { ListItemText, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import menuItems from "./sidebar/businessListItems";
import { financeListItems } from "./sidebar/financeListItems";
import { adminListItems } from "./sidebar/adminListItems";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { deleteAllCookies } from "../utils/getCookie";
import { useFetchTeamInfoQuery, useFetchUserInfoQuery } from "../store/api/infoApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apiSlice } from "../store/api/baseApi";

const logo = require("../assets/logo.png");

const SideNavBarContainer = styled.div<{ $isExpanded?: boolean }>`
  width: ${(props) => (props.$isExpanded ? "300px" : "85px")};
  height: 100vh;
  position: fixed;
  transition: width 0.4s;
  background: #233142;
  color: #f5ecec;
  text-transform: uppercase;
`;

const Brand = styled.div`
  display: flex;
  gap: 5px;
`;

const BrandImage = styled.img`
  width: 60px;
  height: 60px;
  margin: 10px 10px 10px 0px;
`;

const MenuContainer = styled.div`
  margin-left: 5px;
`;

const MenuItem = styled.div<{ $isExpanded?: boolean }>`
  height: 57px;
  width: ${(props) => (props.$isExpanded ? "250px" : "70px")};
  display: flex;
  align-items: center;
  color: #f5ecec;
  text-decoration: none;
  margin: auto 0px;
  gap: 5px;
  border-radius: 10px;
  transition: background-color 0.4s;

  &:hover {
    background-color: #0ac775;
  }
`;

const MenuItemIcon = styled.div`
  width: 20px;
  padding: 0 20px;
`;

const LogoutContainer = styled(MenuItem)<{ $isExpanded?: boolean }>`
  /* margin-top: 20px; */
  padding-left: 25px;
  width: ${(props) => (props.$isExpanded ? "240px" : "50px")};
  /* gap: 20px; */
  align-items: center;
  cursor: pointer;
`;

const NavFooter = styled.div`
  width: 100%;
  height: 87px;
  /* margin-left: 15px; */
  align-items: center;
  bottom: 0;
`;

const NavDetails = styled.div`
  width: 100%;
  max-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 5px;
  /* margin-top: 20px; Add margin-top here */

`;

const NavFooterInfo = styled.div`
  width: 100%;
  max-height: 100px;
  margin-left: 15px;
  margin-bottom: 20px;
  /* flex: 1; */
`;

const NavFooterUserName = styled.p<{ isLong: boolean; isExpanded: boolean }>`
  font-size: 18px;
  font-weight: 900;
  width: 200px;
  max-width: ${(props) => (props.isExpanded ? "200px" : "120px")}; 
  margin-bottom: 30px;
  /* white-space: nowrap; */
  overflow: hidden; 
  text-overflow: ellipsis;
  word-wrap: break-word;

  // Add ellipsis for text exceeding 47 characters
  ${({ isLong }) =>
    isLong &&
    `
    // white-space: normal;
    // overflow: visible;
    // text-overflow: initial;
    // word-wrap: break-word;
  `}
`;


const NavFooterUserPosition = styled.p`
  margin-top: -15px;
  color: var(--gray);
  margin-bottom: 25px;
`;

const NavFooterAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10%;
`;

interface NavbarProps {
  toggleSidebar: () => void;
  isExpanded: boolean;
}

const SideNavBar = ({ isExpanded }: NavbarProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { isSuccess: isTeamInfoFetchSuccess, data: teamDetails } = useFetchTeamInfoQuery();
  const { isSuccess: isUserInfoFetchSuccess, data: userDetails } = useFetchUserInfoQuery();

  const handleLogout = () => {
    deleteAllCookies();
    dispatch(apiSlice.util.resetApiState())
    navigate("/login");
  };

  let userName = userDetails ? userDetails.data.name + " " + userDetails.data.surname : "";
  // If there are more than three names, show only the first name and the last name
  if (userName.split(" ").length > 3) {
    const namesArray = userName.trim().split(" ");
    userName = namesArray[0] + " " + namesArray[namesArray.length - 1];
  }
  const isLongText = userName && userName.length > 40 ? true : false;  

  return (
    <SideNavBarContainer $isExpanded={isExpanded}>
      <Brand>
        <BrandImage src={logo} />
        {isExpanded && <h2 style={{ color: "white" }}>Payeda</h2>}
      </Brand>
      {isTeamInfoFetchSuccess && isUserInfoFetchSuccess && teamDetails && userDetails && (
        <MenuContainer>
          {userDetails.data.team_type === "Business" &&
            menuItems.map(({ text, icon, link }) => {
              if (teamDetails.data.awaitingReceivePaymentApproval === false) {
                return (
                  <Link to={link} key={text}>
                    <MenuItem key={text} $isExpanded={isExpanded}>
                      <MenuItemIcon>{icon}</MenuItemIcon>
                      {isExpanded && <ListItemText primary={text} />}
                    </MenuItem>
                  </Link>
                );
              } else {
                return (
                  <MenuItem key={text} $isExpanded={isExpanded} style={{ pointerEvents: "none", opacity: 0.5 }}>
                    <MenuItemIcon>{icon}</MenuItemIcon>
                    {isExpanded && <ListItemText primary={text} />}
                  </MenuItem>
                );
              }
            })}
          {userDetails.data.team_type === "Finance" &&
            financeListItems.map(({ text, icon, link }) => {
              return (
                <Link to={link} key={text}>
                  <MenuItem key={text} $isExpanded={isExpanded}>
                    <MenuItemIcon>{icon}</MenuItemIcon>
                    {isExpanded && <ListItemText primary={text} />}
                  </MenuItem>
                </Link>
              );
            })}
          {userDetails.data.team_type === "Admin" &&
            adminListItems.map(({ text, icon, link }) => {
              return (
                <Link to={link} key={text}>
                  <MenuItem key={text} $isExpanded={isExpanded}>
                    <MenuItemIcon>{icon}</MenuItemIcon>
                    {isExpanded && <ListItemText primary={text} />}
                  </MenuItem>
                </Link>
              );
            })}
        </MenuContainer>
      )}
      <NavFooter>
        <NavDetails>
          <NavFooterAvatar src={logo} />
          {isExpanded && (
            <NavFooterInfo>
              {/* {isUserInfoFetchSuccess && userDetails ? userDetails.data.name + " " + userDetails.data.surname : ""} */}
              {isLongText ? (
                <Tooltip title={userName}>
                  <NavFooterUserName isLong={isLongText} isExpanded={isExpanded}>{`${userName.substring(0, 40)}...`}</NavFooterUserName>
                </Tooltip>
              ) : (
                <NavFooterUserName isLong={isLongText} isExpanded={isExpanded}>{userName}</NavFooterUserName>
              )}
              <NavFooterUserPosition>{isUserInfoFetchSuccess && userDetails ? userDetails.data.team_type + " " + "Account" : ""}</NavFooterUserPosition>
            </NavFooterInfo>
          )}
        </NavDetails>
        <LogoutContainer $isExpanded={isExpanded} onClick={handleLogout}>
          <LogoutIcon />
          {isExpanded && <Typography>Logout</Typography>}
        </LogoutContainer>
      </NavFooter>
    </SideNavBarContainer>
  );
};

export default SideNavBar;
