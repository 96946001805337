import { Routes, Route } from "react-router-dom";
import CreateInvoiceTab from "./pages/invoice/Index";
import ManageInvoice from "./pages/invoice/manageInvoice";
import ViewInvoiceDetails from "./pages/invoice/manageInvoice/ViewInvoiceDetails";
import ManageStock from "./pages/product/";
import ManageSubcategory from "./pages/subCategory/ManageSubcategory";
import SupplierTabs from "./pages/supplier";
import "./index.css";
import Login from "./pages/authentication/Login";
import SignUp from "./pages/authentication/Signup";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import NonAuthPage from "./components/NonAuthPage";
import EmailVerificationAlert from "./pages/EmailVerificationPrompt";
import SendVerificationEmail from "./pages/ResendVerificationEmail";
import ConfirmEmailVerification from "./pages/ConfirmEmailVerification";
import ForgotPassword from "./pages/ForgotPassword";
import HomePage from "./pages/HomePage";
import VerifyToken from "./pages/VerifyToken";
import CreateNewPassword from "./pages/CreateNewPassword";
import NewPasswordSuccess from "./pages/NewPasswordSuccess";
import theme from "./theme/theme";
import { ThemeProvider } from "@mui/material";
import CreateBusinessAuth from "./components/CreateBusinessAuth";
import AccountTypeSelectionPage from "./pages/createBusiness/AccountTypeSelection";
import CreateBusinessPromptPage from "./pages/createBusiness/CreateBusinessPrompt";
import CompanyRegNumForm from "./pages/createBusiness/CompanyRegNumForm";
import SoleTraderRegOptions from "./pages/createBusiness/SoleTraderRegOption";
import CompanyDetailsForm from "./pages/createBusiness/CompanyDetailsForm";
import CreateAccount from "./pages/createBusiness/CreateAccount";
import RegisteredCompanyDocPage from "./pages/createBusiness/RegisteredCompanyDoc";
import SoloTraderDocPage from "./pages/createBusiness/SoleTraderDocPage";
import CustomerManagement from "./pages/customer/ManageCustomer";
import CreateSalesInvoiceTab from "./pages/salesInvoice/IndexSalesInvoice";
import ShowBankAccount from "./pages/bankAccount/ShowBankAccount";
import SalesInvoiceList from "./pages/salesInvoice/manageSalesInvoice/ManageSalesInvoice";
import OutgoingReceipts from "./pages/receipt/OutgoingReceipts";
import IncomingReceipts from "./pages/receipt/IncomingReceipts";
import ViewSalesInvoiceDetails from "./pages/salesInvoice/manageSalesInvoice/ViewSalesInvoiceDetails";
import OfferManagement from "./pages/finance/offer/ManageOffer";
import BasketManagement from "./pages/finance/basket/ManageBasket";
import ViewBasketDetails from "./pages/finance/basket/ViewBasketDetails";
import FinancingRequestManagement from "./pages/finance/financingRequest/ManageFinancingRequest";

import SettingsPage from "./pages/settings/Settings";
import FactoringRequestedInvoices from "./pages/salesInvoice/manageSalesInvoice/Financing/ManageFactoringRequestedInvoices";
import CreateFinanceAccount from "./pages/admin/createFinanceAccount";
import ManageFinanceAccount from "./pages/admin/ManageFinanceAccount";
import BankAccountRedirectPage from "./pages/redirect/BankAccountRedirectPage";
import BusinessRoutesAuth from "./components/BusinessRouteAuth";
import FinanceRoutesAuth from "./components/FinanceRouteAuth";
import AdminRoutesAuth from "./components/AdminRouteAuth";
import CreatePurchaseReturnInvoice from "./pages/invoice/createInvoice/CreatePurchaseReturnInvoice";
import ConnectBankAccountSteps from "./components/bankAccountConnection";
import { useEffect } from "react";
import useScript from "./hooks/useScript";
function App() {
  useScript();
  

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          {/* Redirect pages*/}
          <Route path="/bank-account-redirect" element={<BankAccountRedirectPage />} />

          <Route element={<NonAuthPage />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<EmailVerificationAlert />} />
            <Route path="/send-verification-email" element={<SendVerificationEmail />} />
            <Route path="/confirmVerificationEmail" element={<ConfirmEmailVerification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/verify-token" element={<VerifyToken />} />
            <Route path="/create-new-password" element={<CreateNewPassword />} />
            <Route path="/new-password-success" element={<NewPasswordSuccess />} />

            <Route path="/bank-account-redirect" element={<BankAccountRedirectPage />} />
          </Route>
          <Route element={<PersistLogin />}>
            <Route element={<CreateBusinessAuth />}>
              <Route path="/create-business-prompt" element={<CreateBusinessPromptPage />} />
              <Route path="/account-type-selection" element={<AccountTypeSelectionPage />} />
              <Route path="/company-reg-num-form" element={<CompanyRegNumForm />} />
              <Route path="/sole-trader-reg-options" element={<SoleTraderRegOptions />} />
              <Route path="/company-details-form" element={<CompanyDetailsForm />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/company-doc-upload" element={<RegisteredCompanyDocPage />} />
              <Route path="/sole-trader-doc-upload" element={<SoloTraderDocPage />} />
            </Route>

            <Route element={<RequireAuth />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route element={<BusinessRoutesAuth />}>
                <Route path="/supplier-tabs" element={<SupplierTabs />} />

                <Route path="/manageCustomer" element={<CustomerManagement />} />
                <Route path="/create-purchase-invoice" element={<CreateInvoiceTab />} />
                <Route path="/create-purchase-return-invoice" element={<CreatePurchaseReturnInvoice />} />
                <Route path="/createSalesInvoice" element={<CreateSalesInvoiceTab />} />
                <Route path="/createBankAccount" element={<ShowBankAccount />} />
                <Route path="/manageInvoice" element={<ManageInvoice />} />
                <Route path="/manageSalesInvoice" element={<SalesInvoiceList />} />
                <Route path="/manageSalesInvoice" element={<SalesInvoiceList />} />
                {/* <Route path="/supplier-credit-note" element={<SupplierCreditNote />} /> */}
                <Route path="/outgoingReceipts" element={<OutgoingReceipts />} />
                <Route path="/incomingReceipts" element={<IncomingReceipts />} />
                <Route path="/viewInvoice/:invoiceId" element={<ViewInvoiceDetails />} />
                <Route path="/viewSalesInvoiceDetails" element={<ViewSalesInvoiceDetails />} />
                <Route path="/factoringRequests" element={<FactoringRequestedInvoices />} />

                <Route path="/manageStock" element={<ManageStock />} />
                <Route path="/manageSubcategory" element={<ManageSubcategory />} />
              </Route>

              {/* Finance Routes */}
              <Route element={<FinanceRoutesAuth />}>
                <Route path="/manageOffer" element={<OfferManagement />} />
                <Route path="/manageBasket" element={<BasketManagement />} />
                <Route path="/viewBasket/:basketId/:supplierName/hasOffer/:hasOffer" element={<ViewBasketDetails />} />
                <Route path="/manageFinancingRequest" element={<FinancingRequestManagement />} />
              </Route>

              <Route element={<AdminRoutesAuth />}>
                <Route path="/manage-finance-account" element={<ManageFinanceAccount />} />
              </Route>
            </Route>
          </Route>
          {/* </Route> */}
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
