import Dashboard from "../components/BusinessDashboard";
import { useFetchTeamInfoQuery, useFetchUserInfoQuery } from "../store/api/infoApi";
import LoadingSpinner from "../components/LoadingSpinner";
import CreateBusinessPromptPage from "./createBusiness/CreateBusinessPrompt";
import WaitingApprovalPage from "./createBusiness/WaitingApproval";
import { useEffect } from "react";
import FinanceDashboard from "../components/FinanceDashboard";
import AdminDashboard from "../components/AdminDashboard";
import { clearStorage } from "../utils/formStorage";
import { createBusinessFormValues } from "./createBusiness/formName";
import useScript from "../hooks/useScript";

function HomePage() {
  const { isLoading: isTeamInfoFetchLoading, isSuccess: isTeamInfoFetchSuccess, data: teamDetails, refetch: refetchTeamDetails } = useFetchTeamInfoQuery();
  const { isLoading: isUserInfoFetchLoading, isSuccess: isUserInfoFetchSuccess, data: userDetails, refetch: refetchUserDetails } = useFetchUserInfoQuery();
    // useScript("http://localhost:3000/bundle.js");

  useEffect(() => {
    refetchTeamDetails();
    refetchUserDetails();
  }, []);

  useEffect(() => {
    if(teamDetails && userDetails){
      const {name, surname, email, id} = userDetails.data; 
      window.setUserDetails({ 
        company_id: 1,
        client_username: `${name} ${surname}`,
        client_email: email,
        client_phone: "+904325566778",
        client_auth_id: 5,
      });
    }
  }, [teamDetails, userDetails])
  


  if (isTeamInfoFetchLoading || isUserInfoFetchLoading) {
    return <LoadingSpinner />;
  } else if (isTeamInfoFetchSuccess && isUserInfoFetchSuccess && teamDetails && userDetails) {
    
    if (userDetails.data.team_type === "Finance") {
      return <FinanceDashboard />;
    } else if (userDetails.data.team_type === "Admin") {
      return <AdminDashboard />;
    } else {
      if (teamDetails.data.approve !== 2) {
        return <CreateBusinessPromptPage />;
      } else if (teamDetails.data.awaitingReceivePaymentApproval === false) {
        return <Dashboard />;
      } else if (teamDetails.data.awaitingReceivePaymentApproval === true) {
        return <WaitingApprovalPage />;
      } else {
        return <LoadingSpinner />;
      }
    }
  } else {
    return <LoadingSpinner />;
  }
}

export default HomePage;