import React, { useEffect } from "react";
// import './bundle.css';  // Import your CSS file

const ExternalScriptLoader = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "http://localhost:5143/bundle.js";
    script.async = true;

    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); 

  return <div></div>;
};

export default ExternalScriptLoader;
